<template></template>

<script setup lang="ts">
  import { computed } from "vue";
  import { FilterMatchMode } from "primevue/api";
  import { defineColumnFilter, defineColumnProps } from "../../lib/defines";
  import { ColumnComponentProps } from "../../lib/interfaces";

  const props = defineProps<ColumnComponentProps>();

  defineExpose(
    Object.assign(
      {},
      defineColumnProps({
        sortable: false,
        filterField: computed(() => props.column.name),
        showAddButton: false,
        showApplyButton: false,
        showClearButton: false,
        showFilterOperator: false,
        showFilterMatchModes: false,
      }),
      defineColumnFilter({
        matchMode: FilterMatchMode.IN,
      }),
    ),
  );
</script>
